.gallery-nothing {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.8rem;
    align-items: center;
}

.gallery-nothing img {
    width: 30rem;
}

.gallery-filters {
    display: flex;
    justify-content: end;
    padding-block-end: 3rem;
}

.gallery-filters input {
    font-size: 1.8rem;
}

@media (min-width: 768px) {
    .gallery-nothing {
        flex-direction: row-reverse;
    }
     
}