/* HEADER */
.main-header {
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    background-color: var(--header);
    height: auto;
    transition: all .5s ease-in;
    background-color: var(--bg-header);
    color: var(--font-header);
    padding-block: 2rem;
}

.main-header h1 {
    font-size: 2.5rem;
}
  
  #burger-link {  
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding: 0;

    position: absolute;
    right: 1rem;
    top: 3rem;
  
  }
  
  #burger-link svg {
    width: 4rem;
  }
  
  .line {
    fill: none;
    stroke: var(--font-header);
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }
  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  }
  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  
  .main-nav {
    grid-column: 1 / 3;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: all .5s ease-in-out;
  }
  
  .main-nav.open {
    opacity: 1;
    height: 100vh;
  }
  
  .nav-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    margin-block-start: 5rem;
    text-transform: uppercase;
    font-size: 2rem;
  }
  
  .nav-link {
    position: relative;
    font-size: 2.4rem;
    text-decoration: none;
    color: inherit;
  }
  
  @media (width > 1000px) {
    .main-header .container {
      display: grid;
      grid-template-columns: auto auto;
      gap: 0 5rem;
      width: 100%;
    }

    #burger-link {
      display: none;
    }

    .logo-subtitle {
      grid-row: 2 / 3;
    }

    .main-nav {
      grid-row: 1/3;
      grid-column: 2;
      opacity: 1;
      display: flex;
      justify-content: space-around;
      height: auto;
      justify-self: end;
    }

    .nav-list {
      flex-direction: row;
      margin-block-start: inherit;
      text-transform: none;
      margin-block-end: 0;
    }
    
    .nav-item {
      margin-inline: 1.5rem;
    }

    .nav-link {
      font-size: 2rem;
    }

  }