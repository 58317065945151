.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.not-found img {
    width: 20rem;
}

.not-found button {
    margin-block-start: 3rem;
    border: 1px solid var(--main-brown);
    border-radius: .5rem;
    background-color: var(--main-sand);
    font-size: 1.8rem;
    padding: .5rem 1rem;
}