/* marck-script-regular - latin */
@font-face {
    font-family: 'Marck Script';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('../fonts/marck-script-v16-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/marck-script-v16-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'Bad Script';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('../fonts/bad-script-v16-latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/bad-script-v16-latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

:root {
    --main-brown: #302B27;
    --main-sand: #FCF5E4;
    --white: #f7f6f3;

    --bg-header: var(--main-brown);
    --font-header: var(--main-sand);
}

body {
    /* font-family: 'Bad Script'; */
    /* font-family: 'Marck Script'; */
    font-family: 'Waterfall';
}

h1 {
    font-size: 3rem;
}

h2 {
    margin-block: 2rem;
}

h3 {
    margin-block: 1.5rem;
}

p {
    line-height: 3rem;
}

.App {
    min-height: 100vh;
    display: grid;
    grid-template-rows: auto 1fr auto;
    align-content: start;
}

main {
    width: 100%;
    height: 100%;
    padding-inline: 3rem;
    padding-block: 4rem;
}

.content {
    margin-block-start: 3rem;
    line-height: 3rem;
}

.content-title {
    padding-block-end: 3rem;
}

@media (min-width: 768px) {

    main,
    .container {
        max-width: 110rem;
        margin: auto;
    }
}