.main-footer {
    background-color: var(--bg-header);
    color: var(--font-header);
    padding-inline: 3rem;
    padding-block: 1.3rem;
    font-size: 1.1rem;
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
}

.main-footer-content, 
.main-footer-legals {
    margin: auto;
    text-align: center;
}

.main-footer-content a {
    color: white;
}

.main-footer-legals {
    font-style: italic;
}